// По хорошему этой функции не должно быть, она импортирует все ссылки на картинки, что плохо. Лучше использовать папку public
// которая может использоваться в реактивном контексте, но в данный момент это не представляется возможным, ввиду проблем с контейнером.
// NB! Удалить это
export default () => {
  const assets: Record<string, string> = import.meta.glob('~/assets/img/**/*', {
    eager: true,
    import: 'default'
  });
  const getAsset = (path: string) => {
    return assets['/assets/img/' + path];
  };
  return {
    assets,
    getAsset
  };
};
